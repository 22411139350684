import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import FooterLogo from '../assets/images/logo.png';

const Footer = () => {

	const { t } = useTranslation();
	const currentYear = new Date().getFullYear();
	
	return (
		<footer className="w-full bg-slate-800" id="footer">
			<div className="w-full flex flex-col items-center gap-4 justify-center py-4 px-4">
				<img src={FooterLogo} alt={process.env.REACT_APP_NAME} className="w-32 mx-auto" />
				<Link
					to="/privacy-policy"
					className="font-roboto p-2 hover:bg-slate-700 text-white font-semibold rounded-md hover:underline"
				>
					{ t('privacy-policy') }
				</Link>
			</div>
			<small className="block w-full p-6 border-t border-slate-700 text-white font-roboto text-center">
				{ process.env.REACT_APP_NAME } &copy; { currentYear } - CNPJ 18.174.902/0001-00 - { t('all-rights-reserved') }
			</small>
		</footer>
	)
}

export default Footer;